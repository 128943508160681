// extracted by mini-css-extract-plugin
export var container = "team-module--container--6b183";
export var contentContainer = "team-module--contentContainer--5c2b8";
export var headContainer = "team-module--headContainer--875bc";
export var header = "team-module--header--4c827";
export var headerContainer = "team-module--headerContainer--3f9a2";
export var headerText = "team-module--headerText--7e161";
export var image = "team-module--image--eb8dd";
export var infoContainer = "team-module--infoContainer--1c91c";
export var infoListContainer = "team-module--infoListContainer--72067";
export var outerContainer = "team-module--outerContainer--6ddf8";
export var reverseContainer = "team-module--reverseContainer--fb3ed";
export var subheader = "team-module--subheader--e2158";