import * as React from "react"
import * as styles from '../css/areaWithOverlay.module.css'

const AreaWithOverlay = (props) => {

    return (
        <div className={styles.container}>
            <div className={styles.area}>
                {props.children}
            </div>
            <div className={styles.overlay}>
                {props.overlayContent}
            </div>
        </div>
    )
}

export default AreaWithOverlay