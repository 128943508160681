// extracted by mini-css-extract-plugin
export var aboutContent = "teamMember-module--aboutContent--f0124";
export var bodyContainer = "teamMember-module--bodyContainer--08e5c";
export var centered = "teamMember-module--centered--9ed61";
export var columns = "teamMember-module--columns--82194";
export var container = "teamMember-module--container--69640";
export var emailContent = "teamMember-module--emailContent--4660b";
export var extraInfo = "teamMember-module--extraInfo--d50b2";
export var flex = "teamMember-module--flex--81e42";
export var flexReverse = "teamMember-module--flexReverse--67d23";
export var image = "teamMember-module--image--1db1e";
export var imageContainer = "teamMember-module--imageContainer--e2b12";
export var infoContainer = "teamMember-module--infoContainer--3808a";
export var name = "teamMember-module--name--14944";
export var overlay = "teamMember-module--overlay--4597c";
export var title = "teamMember-module--title--3d936";