import * as React from "react"
import * as styles from "../css/pytButton.module.css"
 
 const PytButton = (props) => {
   return (
        <button className={styles.button}>
            <h4 className={styles.buttonText}>{props.children}</h4>
        </button>
   )
 }
 
 export default PytButton
 