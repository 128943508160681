import React from "react"
import { GatsbyImage } from 'gatsby-plugin-image'
import { StaticQuery, graphql } from "gatsby"
import * as styles from "../css/igWidget.module.css"
import Break from "./break";


const IgWidget = () => {
    return(
        <StaticQuery
            query={graphql`
                query InstagramContent {
                    allInstagramContent(limit: 13, filter: {caption: {regex: "/^((?!review|regan).)*$/s"}}) {
                        edges {
                            node {
                                caption
                                localImage {
                                childImageSharp {
                                    gatsbyImageData(layout: CONSTRAINED, placeholder: BLURRED, width: 500, height: 500)
                                    }
                                }
                                permalink
                                id
                            }
                        }
                    }
                }
            `}
            render={data => {
                return (<div className={styles.container}>
                    <div className={styles.igContainer}>
                        <div className={styles.igList}>
                            {data.allInstagramContent.edges.map(
                                item => item.node.localImage && (
                                    <a href={item.node.permalink} target="_blank" rel="noreferrer" key={item.node.id}>
                                        <GatsbyImage
                                            className={styles.igImage}
                                            image={item.node.localImage.childImageSharp.gatsbyImageData}
                                            alt={ item.node.caption || 'Instagram Post' }
                                        />
                                    </a>
                                )
                            )}
                        </div>
                    </div>
                    <div className={styles.coverContainer}>
                        <div className={styles.hex}>
                            <div className={styles.hexTop} />
                            <div className={styles.cover}>
                                <h4 className={styles.instaTextHeader}>instagram</h4>
                                <Break />
                                <p className={styles.instaText}>Follow all PYT Co's Updates</p>
                                <p className={styles.instaText}>
                                    <a href="https://instagram.com/perfectlyourstravelco">@perfectlyourstravelco</a>
                                </p>
                            </div>
                            <div className={styles.hexBottom} />
                        </div>
                    </div>
                </div>
                 )
            }
        }
                
        />
    )
  }

export default IgWidget