import * as React from "react"
import Layout from "../components/layout"
import Seo from "../components/seo"
import { StaticQuery, graphql } from "gatsby"
import Cta from "../components/cta"
import TeamMember from "../components/teamMember"

import * as styles from "../css/team.module.css"
import IgWidget from "../components/igWidget"

const TeamPage = () => (
  <Layout>
    <Seo title="Perfectly Yours Travel Co. About Page" />
  <StaticQuery
      query={graphql`
      query TeamMembers {
        allMdx(
          filter: {frontmatter: {key: {eq: "Team"}}}
          sort: {fields: frontmatter___order}
        ) {
          edges {
            node {
              body
              frontmatter {
                name
                title
                trips
                destinations
                image {
                  childImageSharp {
                    gatsbyImageData
                  }
                }
              }
            }
          }
        }
      }
     `}
  render={data => {
    return (
      <div className={styles.container}>
        <div className={styles.headerContainer}>
          <h1 className={styles.headerText}>Meet the Team</h1>
        </div>
        <div className={styles.contentContainer}>
          {data.allMdx.edges.map(
            (item, i) => {
              let reverse = (i % 2) === 1
              return <TeamMember reverse={reverse} data={item.node} key={item.node.frontmatter.name}/>
            }
          )}
        </div>
      </div>
    )
  }}
  />
  <Cta />
  <IgWidget />
</Layout>
)

export default TeamPage
