import { Link } from "gatsby"
import * as React from "react"
import * as styles from "../css/cta.module.css"
import PytButton from "./pytButton"
import Break from "./break"
 
 const Cta = () => {
   return (
      <div className={styles.ctaContainer}>
          <h2 className={styles.ctaHeader}>Ready to start planning?</h2>
          <Link to="/inquire/" >
            <PytButton>INQUIRE</PytButton>
          </Link>
          <Break />
      </div>
   )
 }
 
 export default Cta
 