import * as React from "react"
import { GatsbyImage } from "gatsby-plugin-image"
import Break from "./break"
import {splitLinesFilter} from "../utilities/textHelpers"

import * as styles from "../css/teamMember.module.css"
import AreaWithOverlay from "./areaWithOverlay"

const TeamMember = (props) => {
    let name = props.data.frontmatter.name
    let firstName = name.split(/\s+/)[0].toLowerCase();
    let title = props.data.frontmatter.title
    let trips = props.data.frontmatter.trips
    let destinations = props.data.frontmatter.destinations
    let content = splitLinesFilter(props.data.body)
    let containerStyle = props.reverse ? styles.flexReverse : styles.flex;
  return(
    <div className={`${styles.container} ${containerStyle}`}>

        <AreaWithOverlay className={styles.imageContainer} 
            overlayContent=
            { 
                <div className={styles.extraInfo}>
                    <Break />
                    <div className={styles.columns}>
                        <h4 className={`subheading`}>Favortie trips to plan</h4>
                        <ul className={styles.centered}>
                            {trips.map((p, i) => <li className="body3" key={`${firstName}-trips-${i}`}>{p}</li>)}
                        </ul>
                        <h4 className={`subheading`}>Favorite destinations</h4>
                        <ul className={styles.centered}>
                            {destinations.map((d, i) => <li className="body3" key={`${firstName}-destinations-${i}`}>{d}</li>)}
                        </ul>
                    </div>
                </div>
            }    
        >
            <GatsbyImage
                className={styles.image}
                image={props.data.frontmatter.image?.childImageSharp.gatsbyImageData}
                alt={ name || 'Team Member' }
            />
        </AreaWithOverlay>
        <div className={styles.infoContainer}>
            <h1 className={`${styles.name}`}>{name}</h1>
            <h5 className={`${styles.title} subheading`}>{title}</h5>
            <Break />
            <div className={styles.bodyContainer}>
                {content.map((c, i) => 
                    <p className={styles.aboutContent} key={`${firstName}-content-${i}`}>{c}</p>
                )}
            </div>
            <div className={styles.emailContent}>
                <p className={styles.aboutContent}>Reach out to work with me at <strong>{firstName}@perfectlyourstravel.com</strong></p>
            </div>
        </div>
        
    </div>
)}

export default TeamMember
