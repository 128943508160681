// extracted by mini-css-extract-plugin
export var container = "igWidget-module--container--0d17f";
export var cover = "igWidget-module--cover--542b4";
export var coverContainer = "igWidget-module--coverContainer--9aac4";
export var hex = "igWidget-module--hex--01608";
export var hexBottom = "igWidget-module--hexBottom--a75cc";
export var hexTop = "igWidget-module--hexTop--88b5f";
export var igContainer = "igWidget-module--igContainer--802fa";
export var igImage = "igWidget-module--igImage--cb4f7";
export var igList = "igWidget-module--igList--a4c6e";
export var instaText = "igWidget-module--instaText--a71ad";
export var instaTextHeader = "igWidget-module--instaTextHeader--53f07";